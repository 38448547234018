<template>
    <b-overlay :show="loading" rounded="sm">
        <b-row>
            <b-col cols="12">
                <!-- 1 -->
                <b-card no-body class="mb-4">
                    <h3 class="p-3">User List</h3>

                    <!-- Header -->
                    <b-card-body class="p-3 d-flex justify-content-between">
                        <b-form-group class="mb-2 w-25">
                            <b-input-group>
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Type to Search"
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                        <div class="d-flex align-items-center">
                            <b-button
                                variant="primary"
                                class="mr-2"
                                v-b-modal.modal-user
                                @click="titleModal = 'Create User'"
                                >Add User</b-button
                            >
                        </div>
                    </b-card-body>
                    <!-- End Section -->

                    <!-- Data Table -->
                    <b-table
                        style="min-height: 250px"
                        show-empty
                        responsive
                        small
                        class="mb-0"
                        head-variant="light"
                        :items="users"
                        :filter="filter"
                        :fields="fields"
                        hover
                    >
                        <template #cell(id)="data"> #{{ data.item.id }} </template>
                        <template #cell(isActive)="data">
                            <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                            <b-badge variant="danger" v-else>Archived</b-badge>
                        </template>
                        <template #cell(action)="data">
                            <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                                <b-dropdown-item v-b-modal.modal-user @click="editUser(data.item)"
                                    >Edit User</b-dropdown-item
                                >
                                <b-dropdown-item v-b-modal.modal-user-details @click="viewUser(data.item)"
                                    >View User</b-dropdown-item
                                >
                                <b-dropdown-item
                                    v-b-modal.modal-disable
                                    @click="userSelected = data.item"
                                    v-if="data.item.isActive"
                                    >Disable User</b-dropdown-item
                                >
                                <b-dropdown-item v-b-modal.modal-enable @click="userSelected = data.item" v-else
                                    >Enable User</b-dropdown-item
                                >
                                <b-dropdown-item v-b-modal.modal-remove-roles @click="userSelected = data.item"
                                    >Remove Roles</b-dropdown-item
                                >
                                <b-dropdown-item @click="resetPassword(data.item)">Reset Password</b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                    <!-- End Section -->

                    <!-- Pagination -->
                    <div class="p-3 d-flex align-items-center">
                        <b-form-group
                            label="Per page"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="9"
                            label-size="sm"
                            label-class="fw-medium"
                            class="mb-0"
                        >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                        </b-form-group>
                        <div class="ml-auto">
                            <b-pagination
                                @input="getUsers"
                                v-model="currentPage"
                                :total-rows="totalUsers"
                                :per-page="perPage"
                                align="fill"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </div>
                    <!-- End Section -->
                </b-card>
            </b-col>

            <b-modal id="modal-user" size="lg" hide-footer centered :title="titleModal" @close="closeModal">
                <b-form @submit.prevent="createUser">
                    <div class="rounded bg-light p-3">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <b-form-group label="First Name" label-class="fw-medium mb-1" label-for="firstname">
                                    <b-form-input
                                        id="firstname"
                                        type="text"
                                        placeholder="Your first name here"
                                        v-model="firstName"
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-6">
                                <b-form-group label="Last Name" label-class="fw-medium mb-1" label-for="lastname">
                                    <b-form-input
                                        id="lastname"
                                        type="text"
                                        placeholder="Your lastname here"
                                        v-model="lastName"
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="p-3">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <b-form-group label="Username" label-class="fw-medium mb-1" label-for="username">
                                    <b-form-input
                                        id="username"
                                        type="text"
                                        placeholder="Your username here"
                                        v-model="username"
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-6">
                                <b-form-group label="Password" label-class="fw-medium mb-1" label-for="password">
                                    <b-form-input
                                        id="password"
                                        type="password"
                                        placeholder="Your password here"
                                        v-model="password"
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="rounded bg-light p-3">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <b-form-group label="Email" label-class="fw-medium mb-1" label-for="email">
                                    <b-form-input
                                        id="email"
                                        type="email"
                                        placeholder="Your email here"
                                        v-model="email"
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-6">
                                <b-form-group label="Confirm Email" label-class="fw-medium mb-1" label-for="confirm">
                                    <b-form-input
                                        id="confirm"
                                        type="email"
                                        placeholder="Your email here"
                                        v-model="confirmEmail"
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="p-3">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <b-form-group label="Birth Date" label-class="fw-medium mb-1" label-for="birthDate">
                                    <b-form-input
                                        id="birthDate"
                                        type="date"
                                        placeholder="Your birth date here"
                                        v-model="birthDate"
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-6">
                                <b-form-group label="Phone Number" label-class="fw-medium mb-1" label-for="phoneNumber">
                                    <b-form-input
                                        id="phoneNumber"
                                        type="text"
                                        placeholder="Your phone number here"
                                        v-model="phoneNumber"
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="rounded p-3 bg-light">
                        <b-form-group
                            v-slot="{ ariaDescribedby }"
                            label="Roles for user"
                            label-class="fw-medium mb-1"
                            label-for="roles"
                        >
                            <b-form-checkbox-group
                                v-model="selectedRoles"
                                :aria-describedby="ariaDescribedby"
                                class="d-flex w-100 flex-wrap"
                            >
                                <div v-for="option in filteredRoles" :key="option.id">
                                    <b-form-checkbox :value="option.id" v-model="rolesSelected" name="roles">
                                        {{ option.description }}
                                    </b-form-checkbox>
                                </div>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>

                    <div class="d-flex justify-content-end mt-3">
                        <b-button variant="secondary" @click="closeModal" class="mr-2">Cancel</b-button>
                        <b-button :disabled="loadingUser" variant="primary" type="submit">
                            <b-spinner small v-if="loadingUser"></b-spinner>
                            <span v-else>Save</span>
                        </b-button>
                    </div>
                </b-form>
            </b-modal>

            <b-modal id="modal-user-details" hide-footer centered title="User Details">
                <div class="mb-4">
                    <!-- Message -->
                    <div class="border-bottom d-flex justify-content-between py-2">
                        <h6 class="message-title text-dark mb-0 mt-1">Username</h6>
                        <span class="mail-desc d-block text-muted">{{ userSelected.userName }}</span>
                    </div>
                    <div class="border-bottom d-flex justify-content-between py-2">
                        <h6 class="message-title text-dark mb-0 mt-1">Email</h6>
                        <span class="mail-desc d-block text-muted">{{ userSelected.email }}</span>
                    </div>
                    <div class="border-bottom d-flex justify-content-between py-2">
                        <h6 class="message-title text-dark mb-0 mt-1">Created By</h6>
                        <span class="mail-desc d-block text-muted">{{ userSelected.CreatedByUserName }}</span>
                    </div>
                    <div class="border-bottom d-flex justify-content-between py-2">
                        <h6 class="message-title text-dark mb-0 mt-1">Creation Date</h6>
                        <span class="mail-desc d-block text-muted">{{ userSelected.createDate }}</span>
                    </div>
                    <div class="border-bottom d-flex justify-content-between py-2">
                        <h6 class="message-title text-dark mb-0 mt-1">Modified By</h6>
                        <span class="mail-desc d-block text-muted">{{ userSelected.ModifiedByUserName || 'N/D' }}</span>
                    </div>
                    <div class="border-bottom d-flex justify-content-between py-2">
                        <h6 class="message-title text-dark mb-0 mt-1">Modified Date</h6>
                        <span class="mail-desc d-block text-muted">{{ userSelected.modifiedDate || 'N/D' }}</span>
                    </div>
                </div>
                <b-form-group
                    v-slot="{ ariaDescribedby }"
                    label="Roles for user"
                    label-class="fw-medium mb-1"
                    label-for="roles"
                >
                    <b-form-checkbox-group v-model="selectedRoles" :aria-describedby="ariaDescribedby">
                        <div v-for="option in filteredRoles" :key="option.id">
                            <b-form-checkbox :value="option.id" name="roles" disabled v-model="rolesSelected">
                                {{ option.description }}
                            </b-form-checkbox>
                        </div>
                    </b-form-checkbox-group>
                </b-form-group>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" @click="$bvModal.hide('modal-user-details')">Cancel</b-button>
                </div>
            </b-modal>

            <b-modal id="modal-disable" size="sm" hide-footer centered title="Disable User">
                <b-card-text> Are you sure to want to disable this user? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="danger" class="mr-2" @click="updateStatusUser(false)">Yes</b-button>
                    <b-button variant="secondary" @click="$bvModal.hide('modal-disable')">No</b-button>
                </div>
            </b-modal>

            <b-modal id="modal-remove-roles" size="sm" hide-footer centered title="Disable User">
                <b-card-text> Are you sure to want to remove roles for this user? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="danger" class="mr-2" @click="removeRoles">Yes</b-button>
                    <b-button variant="secondary" @click="$bvModal.hide('modal-remove-roles')">No</b-button>
                </div>
            </b-modal>

            <b-modal id="modal-enable" size="sm" hide-footer centered title="Enable User">
                <b-card-text> Are you sure to want to enable this user? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="success" class="mr-2" @click="updateStatusUser(true)">Yes</b-button>
                    <b-button variant="secondary" @click="$bvModal.hide('modal-enable')">No</b-button>
                </div>
            </b-modal>
        </b-row>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'Users',

    data: () => ({
        page: {
            title: 'Users',
        },
        filter: null,
        fields: [
            {
                key: 'id',
                sortable: true,
            },
            {
                key: 'firstName',
                label: 'First Name',
                sortable: true,
            },
            {
                key: 'lastName',
                label: 'Last Name',
                sortable: true,
            },
            {
                key: 'userName',
                label: 'Username',
                sortable: true,
            },
            {
                key: 'email',
                label: 'Email',
                sortable: true,
            },
            {
                key: 'isActive',
                label: 'Status',
                sortable: true,
            },
            {
                key: 'action',
                label: 'Actions',
                sortable: false,
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        titleModal: 'Create User',
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        confirmEmail: '',
        password: '',
        birthDate: '',
        phoneNumber: '',
        rolesSelected: [],
        loading: false,
        loadingUser: false,
        userSelected: {},
        selectedRoles: [],
        isEditing: false,
    }),
    methods: {
        onRowClicked(item) {
            this.$router.push(`/customers/${item.id}`);
        },
        closeModal() {
            this.isEditing = false;
            this.firstName = '';
            this.lastName = '';
            this.username = '';
            this.email = '';
            this.confirmEmail = '';
            this.password = '';
            this.birthDate = '';
            this.phoneNumber = '';
            this.role = null;
            this.titleModal = 'Create User';
        },
        async viewUser(user) {
            const userData = await this.$store.dispatch('user/get_user', user.id);

            this.userSelected = userData;
            this.rolesSelected = userData.roles.map((x) => x.id);
            this.$bvModal.show('modal-user-details');
        },
        async editUser(user) {
            const date = user.birthDate.split('T')[0];
            const userData = await this.$store.dispatch('user/get_user', user.id);

            this.isEditing = true;
            this.userSelected = userData;
            this.rolesSelected = userData.roles.map((x) => x.id);
            this.firstName = userData.firstName;
            this.lastName = userData.lastName;
            this.username = userData.userName;
            this.email = userData.email;
            this.confirmEmail = userData.email;
            this.password = userData.password;
            this.birthDate = date;
            this.phoneNumber = userData.phoneNumber;
            this.role = userData.roleId;
            this.titleModal = 'Edit User';
        },
        async getUsers() {
            this.loading = true;
            let data = {
                skip: this.currentPage,
                take: this.perPage,
            };
            await this.$store.dispatch('user/get_allUsers', data);
            this.loading = false;
        },
        async getData() {
            this.loading = true;
            await this.getUsers();
            await this.$store.dispatch('roles/get_allRoles', { skip: this.currentPage, take: this.perPage });
            this.loading = false;
        },
        async createUser() {
            this.loadingUser = true;
            let data = {
                firstName: this.firstName,
                lastName: this.lastName,
                userName: this.username,
                email: this.email,
                profilePicture: '',
                phoneNumber: this.phoneNumber,
                birthDate: this.birthDate,
                password: this.password,
                createdBy: 1,
                isActive: true,
                roleId: this.role,
            };

            if (this.isEditing) {
                data.id = this.userSelected.id;
                data.modifiedBy = 1;

                const result = await this.$store.dispatch('user/update_user', data);
                let dataRole = {
                    userId: this.userSelected.id,
                    modifiedBy: 1,
                };
                await this.$store.dispatch('user/remove_userRole', dataRole);

                await this.addRoles(result.dataResult);
            } else {
                const result = await this.$store.dispatch('user/add_user', data);
                await this.addRoles(result.dataResult);
            }
            await this.getUsers();
            this.closeModal();
            this.$bvModal.hide('modal-user');
            this.loadingUser = false;
        },
        async addRoles(id) {
            let data = {
                userId: id,
                roleIds: this.rolesSelected,
                createdBy: 1,
            };

            this.$store.dispatch('user/add_userRole', data);
        },
        async updateStatusUser(status) {
            let data = {
                id: this.userSelected.id,
                modifiedBy: 1,
                isActive: status,
            };

            await this.$store.dispatch('user/update_userStatus', data);
            await this.getUsers();
            if (status) {
                this.$bvModal.hide('modal-enable');
            } else {
                this.$bvModal.hide('modal-disable');
            }
        },
        async removeRoles() {
            let data = {
                userId: this.userSelected.id,
                modifiedBy: 1,
            };
            this.rolesSelected = [];
            await this.$store.dispatch('user/remove_userRole', data);
            this.$bvModal.hide('modal-remove-roles');
        },
        async resetPassword(data) {
            await this.$store.dispatch('auth/createForgotPwdToken', data.id);
        },
    },
    computed: {
        ...mapGetters({
            users: 'user/getUsers',
            roles: 'roles/getRoles',
            totalUsers: 'user/getTotalUsers',
        }),
        filteredRoles() {
            return this.roles.filter((x) => x.isActive);
        },
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.totalUsers;
    },
    created() {
        this.getData();
    },
};
</script>
